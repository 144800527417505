import { Spinner } from "@chakra-ui/spinner";
import { Suspense } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { Layout } from "../components";
import { FileUploader } from "../components/FileUploader";
import Uploads from "../containers/uploads";
import UploadFiles from "../containers/uploadFiles";
import { FileViewer } from "../containers/uploadFiles/components";

const routes = [
  {
    path: "uploads/*",
    element: <Uploads />
  },
  {
    path: "uploads/:uploadId",
    element: <UploadFiles />
  },
  {
    path: "uploads/:uploadId/:fileName",
    element: <FileViewer />
  },
  {
    path: "upload",
    element: (
      <FileUploader
        maxFiles={100}
        allowedFileTypes={["image/jpeg", "image/png", "application/pdf"]}
      />
    )
  },
  {
    path: "*",
    element: <Navigate to="uploads" />
  }
];

const AppLayoutWrapper = () => (
  <Layout>
    <Suspense fallback={<Spinner />}>
      <Outlet />
    </Suspense>
  </Layout>
);

export const privateRoutes = () => {
  return [{ path: "/*", element: <AppLayoutWrapper />, children: routes }];
};
