import { Flex, useBreakpointValue } from "@chakra-ui/react";
import { valueToPercent } from "../../helpers";
import PropTypes from "prop-types";

const GRID_MAX = 12;

const getFlexPropsForColSize = size => {
  return typeof size === "number"
    ? {
        flexBasis: `${valueToPercent(0, GRID_MAX, size)}%`,
        maxWidth: `${valueToPercent(0, GRID_MAX, size)}%`
      }
    : size === true
    ? { flexGrow: 1, flexBasis: 0, maxWidth: "100%" }
    : {};
};

const Col = ({ spacing, xs, sm, md, lg, xl, children, ...otherProps }) => {
  const breakPointValues = {
    ...(xs && { base: getFlexPropsForColSize(xs) }),
    ...(sm && { sm: getFlexPropsForColSize(sm) }),
    ...(md && { md: getFlexPropsForColSize(md) }),
    ...(lg && { lg: getFlexPropsForColSize(lg) }),
    ...(xl && { xl: getFlexPropsForColSize(xl) })
  };

  const colStyles = useBreakpointValue(breakPointValues);

  return (
    <Flex
      boxSizing="border-box"
      grow="0"
      shrink={"0"}
      basis={"100%"}
      px={spacing}
      maxW={"100%"}
      sx={colStyles}
      {...otherProps}
    >
      {children}
    </Flex>
  );
};

Col.propTypes = {
  sm: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  xs: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  md: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  lg: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  xl: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  spacing: PropTypes.string
};

Col.defaultProps = {
  sm: undefined,
  xs: undefined,
  md: undefined,
  lg: undefined,
  xl: undefined,
  spacing: "0.5rem"
};

export default Col;
