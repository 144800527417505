import { Navigate, Route, Routes } from "react-router-dom";
import Login from "../views/Login";
import Register from "../views/Register";

const AuthRoutes = () => {
  return (
    <Routes>
      <Route path={"login"} element={<Login />} />
      <Route path={"register"} element={<Register />} />
      <Route path={"/"} element={<Navigate to="/login" replace />} />
      <Route path={"*"} element={<Navigate to="/login" />} />
    </Routes>
  );
};

export default AuthRoutes;
