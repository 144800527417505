import { Box, Flex, Heading } from "@chakra-ui/layout";
import { GoosfrabaLogoType } from "../../../assets/logos";

const Layout = ({ title, children }) => {
  return (
    <Box w="100%" minH="100vh" h="fit-content" overflowY="auto">
      <Flex justify="center" align="center" w="100%" minH="100vh" p="4">
        <Flex
          w={{ base: "100%", md: "md" }}
          align="center"
          direction="column"
          borderWidth="1px"
          borderRadius="lg"
          css={{ gap: "1.5rem" }}
          p={{ base: "4", md: "8" }}
        >
          <Box w="50%">
            <GoosfrabaLogoType />
          </Box>
          <Heading as="h6" size="md">
            {title}
          </Heading>
          <Box w="100%">{children}</Box>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Layout;
