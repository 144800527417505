import { Flex } from "@chakra-ui/layout";
import { Table } from "../../../../components/Table";
import { DateRangePicker, StatusPill } from "../../../../components";
import { useState } from "react";
import { formatTimestamp, getLastSevenDaysRange } from "../../../../helpers";
import ViewUploadButton from "../ViewUploadButton";
import { getUploads } from "../../api";
import UploadExportButton from "../UploadExportButton/UploadExportButton";
import { Tag, TagLabel, TagLeftIcon } from "@chakra-ui/react";
import { TimeIcon } from "@chakra-ui/icons";
import DeleteExportButton from "../DeleteExportButton";

const UploadsTable = () => {
  const [dateRange, setDateRange] = useState(getLastSevenDaysRange());

  const columns = [
    {
      fields: ["timestamp"],
      title: "Uploaded at",
      size: 4,
      renderer: ({ rowData }) => (
        <Tag size={"md"} key={"md"} variant="subtle" colorScheme="gray">
          <TagLeftIcon boxSize="12px" as={TimeIcon} />
          <TagLabel>
            {rowData?.timestamp
              ? formatTimestamp(parseInt(rowData.timestamp) * 1000)
              : "N/A"}
          </TagLabel>
        </Tag>
      ),
      sortable: true
    },
    {
      fields: ["status"],
      title: "Status",
      size: 4,
      renderer: ({ rowData }) => <StatusPill status={rowData.status} />,
      filter: {
        field: "status",
        name: "status",
        defaultValue: [],
        options: [
          { value: ["PROCESSED"], display: "Processed" },
          { value: ["PROCESSING"], display: "Processing" }
        ]
      }
    }
  ];

  const actions = {
    size: 4,
    title: "ACTIONS",
    align: "right",
    actions: [
      <ViewUploadButton />,
      <UploadExportButton />,
      <DeleteExportButton />
    ]
  };

  return (
    <Flex
      direction={"column"}
      width={"100%"}
      overflowX={"hidden"}
      maxW={"100%"}
      justify={"center"}
      align={"center"}
      justifyContent={"center"}
    >
      <Flex width={"100%"} mb={"0.5rem"}>
        <DateRangePicker
          defaultSelected={dateRange}
          onChange={setDateRange}
          waitForValidInput
        />
      </Flex>

      <Table
        rowMinWidth={"50"}
        identifier={"uploads"}
        highlightRows={"odd"}
        hoverable={true}
        columns={columns}
        actions={actions}
        initialQueryVars={{
          range: {
            timestamp: {
              from: (dateRange.from.getTime() / 1000).toString(),
              to: (dateRange.to.getTime() / 1000).toString()
            }
          },
          sort: { timestamp: "ASC" }
        }}
        idKey={"uploadId"}
        query={getUploads}
      />
    </Flex>
  );
};

export default UploadsTable;
