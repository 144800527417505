import { Heading } from "@chakra-ui/react";
import { UploadFilesTable } from "../components";

const UploadFiles = () => {
  return (
    <>
      <Heading as="h1" size="lg" mb="4">
        Files
      </Heading>
      <UploadFilesTable />
    </>
  );
};

export default UploadFiles;
