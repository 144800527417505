import { Heading } from "@chakra-ui/react";
import { UploadsTable } from "../components";

const Uploads = () => {
  return (
    <>
      <Heading as="h1" size="lg" mb="4">
        Uploaded Documents
      </Heading>
      <UploadsTable />
    </>
  );
};

export default Uploads;
