import {
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement
} from "@chakra-ui/input";
import FieldWrapper from "../FieldWrapper";
import PropTypes from "prop-types";

const InputField = ({
  name,
  icon,
  validate,
  isReadOnly,
  isRequired,
  label,
  helperMessage,
  hideCheckIcon,
  ...inputProps
}) => {
  return (
    <FieldWrapper
      name={name}
      validate={validate}
      isReadOnly={isReadOnly}
      isRequired={isRequired}
      label={label}
      helperMessage={helperMessage}
    >
      {({ isValid, field }) => (
        <InputGroup size={inputProps?.size}>
          {icon && (
            <InputLeftElement
              pointerEvents={"none"}
              color={"gray.400"}
              fontSize={"1rem"}
              zIndex={"0"}
            >
              <i className={`far fa-${icon}`} />
            </InputLeftElement>
          )}
          <Input id={name} {...field} {...inputProps} />
          {!hideCheckIcon && isValid && !isReadOnly && (
            <InputRightElement
              pointerEvents={"none"}
              color={"green.500"}
              fontSize={"1rem"}
              zIndex={"0"}
            >
              {" "}
              <i className="far fa-check" />
            </InputRightElement>
          )}
        </InputGroup>
      )}
    </FieldWrapper>
  );
};

InputField.propTypes = {
  name: PropTypes.string.isRequired,
  validate: PropTypes.func,
  isReadOnly: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  helperMessage: PropTypes.string
};

export default InputField;
