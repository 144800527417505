import React from "react";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { extendedTheme } from "./extendedTheme";

const theme = extendTheme(extendedTheme);

const ThemeProvider = ({ children }) => {
  return <ChakraProvider theme={theme}>{children}</ChakraProvider>;
};

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default ThemeProvider;
