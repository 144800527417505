import { Flex, Heading } from "@chakra-ui/layout";
import { Spinner } from "@chakra-ui/spinner";

const LoadingScreen = () => {
  return (
    <Flex
      w="100vw"
      h="100vh"
      align="center"
      justify="center"
      direction="column"
    >
      <Heading as="h1" size="md" textAlign="center" mb="6">
        Aplicatia se incarca...
      </Heading>
      <Spinner
        size="xl"
        w="10rem"
        h="10rem"
        thickness="10px"
        emptyColor="gray.200"
        color="yellow.400"
      />
    </Flex>
  );
};

export default LoadingScreen;
