import { Alert } from "..";

const TableError = ({ errorMessage }) => {
  return (
    <Alert
      status={"error"}
      title={"Eroare"}
      description={
        errorMessage ??
        "Tabelul nu poate fi incarcat. Incercati sa reincarcati pagina."
      }
      w="100%"
    />
  );
};

export default TableError;
