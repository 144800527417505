import { Button, Tooltip } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const ViewUploadButton = ({ row }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/uploads/${row.uploadId}`);
  };

  return (
    <Tooltip hasArrow label="Go to uploaded files">
      <Button onClick={handleClick}>
        <i className="fas fa-folder-open" />
      </Button>
    </Tooltip>
  );
};

export default ViewUploadButton;
