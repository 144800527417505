import { useEffect, useState } from "react";
import {
  Alert as ChakraAlert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  CloseButton
} from "@chakra-ui/react";
import { Flex } from "@chakra-ui/layout";
import PropTypes from "prop-types";

const Alert = ({
  show,
  title,
  description,
  status,
  dismissible,
  onClose,
  ...otherProps
}) => {
  const [shown, setShown] = useState(show ?? true);

  useEffect(() => {
    setShown(show ?? true);
  }, [show]);

  const handleClose = () => {
    onClose();
    setShown(false);
  };

  if (!shown) return null;

  return (
    <ChakraAlert status={status} alignItems={"center"} {...otherProps}>
      <AlertIcon />
      <Flex direction="column" flex="1">
        <AlertTitle>{title}</AlertTitle>
        <AlertDescription display={"block"}>{description}</AlertDescription>
      </Flex>
      {dismissible && <CloseButton onClick={handleClose} />}
    </ChakraAlert>
  );
};

Alert.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  status: PropTypes.oneOf(["info", "warning", "success", "error", undefined]),
  dismissible: PropTypes.bool,
  onClose: PropTypes.func
};

Alert.defaultProps = {
  onClose: () => {}
};

export default Alert;
