import React from "react";
import { Flex } from "@chakra-ui/layout";
import PropTypes from "prop-types";

const Row = ({ spacing, children, reverse, ...otherProps }) => {
  return (
    <Flex
      boxSizing="border-box"
      wrap="wrap"
      mx={`-${spacing}`}
      direction={reverse ? "row-reverse" : "row"}
      grow="0"
      shrink="1"
      basis="auto"
      {...otherProps}
    >
      {children}
    </Flex>
  );
};

Row.propTypes = {
  spacing: PropTypes.string,
  reverse: PropTypes.bool
};

Row.defaultProps = {
  spacing: "0.5rem",
  reverse: false
};

export default Row;
