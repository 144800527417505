import { useToast } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

const DataProvider = ({ children }) => {
  const toast = useToast();

  const queryConfig = {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      onError: error => {
        const errorMessage = error?.response?.data?.message ?? error.message;
        errorMessage &&
          toast({
            title: "Eroare",
            description: errorMessage,
            status: error,
            duration: 9000,
            isClosable: true
          });
      }
    }
  };

  const queryClient = new QueryClient({ defaultOptions: queryConfig });

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {process.env.NODE_ENV === "development" && <ReactQueryDevtools />}{" "}
    </QueryClientProvider>
  );
};

export default DataProvider;
