import { Button, ButtonGroup } from "@chakra-ui/button";
import { Badge, Flex, Spacer } from "@chakra-ui/layout";
import { useMatch, useNavigate } from "react-router-dom";
import { GoosfrabaLogoType } from "../../assets/logos";
import { useAuth } from "../../auth";
import { Box } from "@chakra-ui/react";

const NavigationLink = ({ label, path, icon }) => {
  const isMatching = useMatch(`${path}/*`);
  const navigate = useNavigate();

  return (
    <Button
      leftIcon={icon}
      variant={"link"}
      color="gray.500"
      isActive={isMatching}
      _hover={{
        color: "gray.600",
        textDecoration: "underline",
        textDecorationColor: "blue.300",
        textDecorationThickness: "3px"
      }}
      _active={{
        color: "black",
        textDecoration: "underline",
        textDecorationColor: "blue.400",
        textDecorationThickness: "3px"
      }}
      onClick={() => navigate(path)}
    >
      {label}
    </Button>
  );
};

const Navigation = () => {
  return (
    <ButtonGroup variant={"link"} spacing={"8"}>
      <NavigationLink
        key={"uploads"}
        path={"/uploads"}
        label={"Uploaded Documents"}
        icon={<i className="fas fa-list" />}
      />
      <NavigationLink
        key={"upload"}
        path={"/upload"}
        label={"Upload"}
        icon={<i className="fas fa-upload" />}
      />
    </ButtonGroup>
  );
};

const LogoutButton = () => {
  const { logout } = useAuth();

  return (
    <Button
      leftIcon={<i className="far fa-sign-out" />}
      onClick={logout}
      variant="outline"
      colorScheme="blue"
    >
      Logout
    </Button>
  );
};

const TopBar = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleLogoClick = () => navigate("/");

  return (
    <Flex
      w="100%"
      borderBottom="1px"
      borderColor="gray.200"
      p="4"
      align="center"
    >
      <Box w="10rem" mr="10" onClick={handleLogoClick} cursor="pointer">
        <GoosfrabaLogoType />
      </Box>
      <Navigation />
      <Spacer />
      <Badge mr="4" fontSize="md" colorScheme="green" borderRadius="md">
        {`${user?.email}`}
      </Badge>
      <LogoutButton />
    </Flex>
  );
};

export default TopBar;
