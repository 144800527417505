import { Flex } from "@chakra-ui/layout";
import { Alert, Form, InputField } from "../../../components";
import { useState } from "react";
import { useAuth } from "../..";
import * as yup from "yup";
import { Button } from "@chakra-ui/button";
import PropTypes from "prop-types";

const LoginForm = ({ onSuccess }) => {
  const { login } = useAuth();
  const [serversideError, setServersideError] = useState(false);

  const LoginValidation = yup.object().shape({
    email: yup
      .string()
      .required("Please enter an email address")
      .matches(/^[\S]+$/, "No whitespace is allowed"),
    password: yup.string().required("Please enter a password")
  });

  return (
    <Flex flexDirection="column">
      <Alert
        show={!!serversideError}
        description={serversideError || "error"}
        status="warning"
        dismissible
        onClose={() => setServersideError(false)}
        mb="6"
      ></Alert>
      <Form
        initialValues={{ email: "", password: "" }}
        onSubmit={async (values, actions) => {
          try {
            await login(values);
            onSuccess();
          } catch (error) {
            const errorResponse = error.message;

            setServersideError(errorResponse);
            actions.setSubmitting(false);
          }
        }}
        validationSchema={LoginValidation}
      >
        {formikProps => (
          <Flex
            flexDirection={"column"}
            align={"center"}
            css={{ gap: "1.5rem" }}
          >
            <InputField
              name="email"
              label="User"
              placeholder="User"
              hideCheckIcon
            />
            <InputField
              name="password"
              type="password"
              label="Password"
              placeholder="Password"
              hideCheckIcon
              autoComplete="current-password"
            />
            <Button
              type="submit"
              isLoading={formikProps.isSubmitting}
              isDisabled={!formikProps.isValid && !formikProps.dirty}
              colorScheme={"blue"}
            >
              Login
            </Button>
          </Flex>
        )}
      </Form>
    </Flex>
  );
};

LoginForm.propTypes = {
  onSuccess: PropTypes.func
};

export default LoginForm;
