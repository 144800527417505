import { Button, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useAuth } from "../..";
import * as yup from "yup";
import { Flex } from "@chakra-ui/layout";
import { Alert, Form, InputField } from "../../../components";

const RegisterForm = ({ onSuccess }) => {
  const toast = useToast();
  const { register } = useAuth();
  const [serversideError, setServersideError] = useState();

  const RegisterValidation = yup.object().shape({
    email: yup
      .string()
      .email("Please enter a valid email address")
      .required("Please enter an email address"),
    password: yup
      .string()
      .min(8, "Password must have at least 8 characters")
      .required("Please enter a password")
      .matches(
        /^(?=.*[a-z]).*$/,
        "Password must have at least one lowercase letter"
      )
      .matches(
        /^(?=.*[A-Z]).*$/,
        "Password must have at least one uppercase letter"
      )
      .matches(
        /^(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]).*$/,
        "Password must have at least one special character"
      ),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must be the same")
      .required("Please repeat the password")
  });

  return (
    <Flex flexDirection="column">
      <Alert
        show={!!serversideError}
        description={serversideError}
        status="warning"
        dismissible
        onClose={() => setServersideError(false)}
        mb="6"
      />
      <Form
        initialValues={{
          email: "",
          password: "",
          confirmPassword: ""
        }}
        validateOnMount={true}
        validateOnChange={true}
        onSubmit={async (values, actions) => {
          try {
            const { email, password } = values;
            const data = { email, password, username: email };

            await register(data);

            toast({
              title: "Account created",
              description: "Your account was successfully created!",
              status: "success",
              duration: 9000,
              isClosable: true
            });

            onSuccess();
          } catch (error) {
            console.log(error.message);
            const errorResponse = error.message;

            setServersideError(errorResponse);
            actions.setSubmitting(false);
          }
        }}
        validationSchema={RegisterValidation}
      >
        {formikProps => {
          return (
            <Flex
              flexDirection={"column"}
              css={{ gap: "1.5rem" }}
              align={"center"}
            >
              <InputField name="email" label="User" placeholder="User" />{" "}
              <InputField
                name="password"
                type="password"
                label="Password"
                placeholder="Password"
                autoComplete="new-password"
              />{" "}
              <InputField
                name="confirmPassword"
                type="password"
                label="Confirm password"
                placeholder="Repeat password"
              />
              <Button
                type="submit"
                isLoading={formikProps.isSubmitting}
                isDisabled={!formikProps.isValid || !formikProps.dirty}
                colorScheme={"blue"}
              >
                Register
              </Button>
            </Flex>
          );
        }}
      </Form>
    </Flex>
  );
};

export default RegisterForm;
