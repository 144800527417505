import { Flex } from "@chakra-ui/layout";
import { Row, Col } from "../FlexboxGrid";
import { Skeleton } from "@chakra-ui/skeleton";
import { cloneElement } from "react";

const TableRow = ({
  rowMinWidth = "50",
  hoverable,
  columns,
  highlighted,
  identifier,
  loading,
  rowData,
  rowIndex,
  actions,
  currentQueryVars,
  refetch
}) => {
  const translateAlignToFlex = align =>
    align === "left" ? "flex-start" : align === "right" ? "flex-end" : "center";

  const renderCell = column => {
    const columnContent = (
      <Flex
        h="100%"
        minW={"0"}
        w={"100%"}
        boxSizing="border-box"
        color={"gray.700"}
        lineHeight={"4"}
        px={"4"}
        py={"4"}
        align={"center"}
        justify={translateAlignToFlex(column.align || "left")}
      >
        {loading ? (
          <Skeleton w={"100%"} height={"1rem"} />
        ) : column.renderer ? (
          column.renderer({ rowData, rowIndex })
        ) : (
          Object.byString(rowData, column.fields[0])
        )}
      </Flex>
    );

    return columnContent;
  };

  return (
    <Row
      as="tr"
      spacing="0"
      wrap="nowrap"
      minWidth={`${rowMinWidth}rem`}
      sx={{
        "td:last-child": { borderRight: 0 },
        "&:last-child > *": { borderBottom: 0 }
      }}
      {...(hoverable && { _hover: { "> *": { bg: "blue.50" } } })}
    >
      {columns.map(column => {
        return (
          <Col
            bg={highlighted ? "gray.50" : "white"}
            spacing="0"
            as="td"
            borderRight={column.sticky === "right" ? 0 : "1px"}
            borderLeft={column.sticky === "right" ? "1px" : 0}
            borderBottom="1px"
            borderColor="gray.300"
            xs={column.size}
            key={`${identifier}-${column.title}-${column.fields[0]}`}
            align="center"
            justify="center"
            {...(column.sticky && { position: "sticky", [column.sticky]: 0 })}
          >
            {renderCell(column)}
          </Col>
        );
      })}

      {typeof actions !== "undefined" && (
        <Col
          key={`${identifier}-actions`}
          bg={highlighted ? "gray.50" : "white"}
          spacing={"0"}
          as="td"
          borderRight="1px"
          borderBottom="1px"
          borderColor="gray.300"
          xs={actions.size}
        >
          <Flex
            align="center"
            px={"4"}
            w={"100%"}
            justify={translateAlignToFlex(actions.align || "left")}
            wrap="wrap"
            sx={{ gap: "0.2rem" }}
          >
            {loading ? (
              <Skeleton w={"100%"} height={"1rem"} />
            ) : (
              actions.actions.map((action, index) =>
                cloneElement(action, {
                  row: rowData,
                  currentQueryVars,
                  refetch,
                  index: rowIndex,
                  key: `${identifier}-action-${index}`
                })
              )
            )}
          </Flex>
        </Col>
      )}
    </Row>
  );
};

export default TableRow;
