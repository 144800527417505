import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel
} from "@chakra-ui/form-control";
import { useField } from "formik";
import { cloneElement } from "react";
import PropTypes from "prop-types";

const FieldWrapper = ({
  name,
  validate,
  isReadOnly,
  isRequired,
  label,
  helperMessage,
  children
}) => {
  const [field, meta, helpers] = useField({ name, validate });
  const isInvalid = meta.error && meta.touched;
  const isValid = !meta.error && meta.touched;

  return (
    <FormControl
      id={name}
      isInvalid={isInvalid}
      isReadOnly={isReadOnly}
      isRequired={isRequired}
    >
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      {typeof children === "function"
        ? children({ isValid, field, meta, helpers })
        : cloneElement(children, {
            isValid,
            field,
            meta,
            helpers
          })}
      {helperMessage && <FormHelperText>{helperMessage}</FormHelperText>}
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};

FieldWrapper.propTypes = {
  id: PropTypes.string,
  validate: PropTypes.func,
  isReadOnly: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  helperMessage: PropTypes.string,
  children: PropTypes.any.isRequired
};

export default FieldWrapper;
