import { Routes, Route, Navigate } from "react-router-dom";
import Uploads from "../views/Uploads";

const UploadsRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Uploads />} />
      <Route path="*" element={<Navigate to="." />} />
    </Routes>
  );
};

export default UploadsRoutes;
