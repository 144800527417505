import { Flex, Text, Divider, Box } from "@chakra-ui/layout";
import {
  Button,
  Input,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag
} from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import FileCardList from "./FileCardList";
import { useMemo, useState } from "react";
import { useTheme } from "@chakra-ui/react";

const FileUploader = ({ allowedFileTypes, maxFiles }) => {
  const theme = useTheme();

  const baseStyle = useMemo(() => {
    return {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      padding: "2rem",
      borderWidth: "3px",
      borderRadius: "0.25rem",
      borderColor: theme.colors.blue[400],
      borderStyle: "dashed",
      backgroundColor: theme.colors.blue[50],
      outline: "none",
      transition: "all .24s ease-in-out",
      width: "100%"
    };
  }, [theme]);

  const focusedStyle = useMemo(() => {
    return {
      borderColor: theme.colors.blue[600],
      backgroundColor: theme.colors.blue[100]
    };
  }, [theme]);

  const addMoreFiles = ({ newFiles, oldFiles }) => {
    return newFiles.reduce(
      (acc, item) => {
        return [
          ...acc,
          ...(acc.some(accItem => accItem.path === item.path) ? [] : [item])
        ];
      },
      [...oldFiles]
    );
  };

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
    open
  } = useDropzone({
    accept: allowedFileTypes,
    maxFiles: maxFiles,
    onDrop: (acceptedFiles, rejectedFiles) => {
      setGoodFiles(oldAcceptedFiles =>
        addMoreFiles({ newFiles: acceptedFiles, oldFiles: oldAcceptedFiles })
      );
      setBadFiles(oldRejectedFiles =>
        addMoreFiles({ newFiles: rejectedFiles, oldFiles: oldRejectedFiles })
      );
    },
    noClick: true,
    noKeyboard: true
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? focusedStyle : {})
    }),
    [focusedStyle, baseStyle, isDragActive]
  );

  const [goodFiles, setGoodFiles] = useState(acceptedFiles);
  const [badFiles, setBadFiles] = useState(fileRejections);

  const uploaderZoneCallToAction = useMemo(() => {
    return (
      <Flex
        w="100%"
        align="center"
        direction="column"
        css={{ gap: "0.3rem" }}
        textAlign="center"
      >
        <Flex fontSize="5rem" color="blue.300">
          <i className="fad fa-plus-circle" />
        </Flex>
        <Text fontSize="xl" fontWeight="500">
          Drag and drop files here
        </Text>

        <Flex align="center" css={{ gap: "0.5rem" }} fontWeight="600">
          <Divider borderWidth="1.5px" width="3rem" borderColor="black" />
          <Text fontSize="md" opacity={0.6} color="black">
            OR
          </Text>
          <Divider borderWidth="1.5px" width="3rem" borderColor="black" />
        </Flex>
        <Button iconBefore="folders" onClick={open} colorScheme="blue">
          Browse files
        </Button>
      </Flex>
    );
  }, [open]);

  const goodFilesLabel = goodFiles.length
    ? `${goodFiles.length} file${goodFiles.length > 1 ? "s" : ""} accepted...`
    : "There are no accepted files yet...";

  const badFilesLabel = badFiles.length
    ? `${badFiles.length} file${badFiles.length > 1 ? "s" : ""} rejected...`
    : "Hurray! There are no rejected files.";

  return (
    <Flex
      width={"100%"}
      align={"center"}
      direction={"column"}
      css={{ gap: "2rem" }}
    >
      <Box {...getRootProps({ className: "dropzone", style })}>
        {uploaderZoneCallToAction}
        <Input {...getInputProps()} width={"100%"} />
      </Box>

      {(!!goodFiles?.length || !!badFiles?.length) && (
        <Tabs
          variant="enclosed"
          colorScheme="gray"
          isFitted
          defaultIndex={0}
          w={"100%"}
          mt={"0.25rem"}
        >
          <TabList>
            <Tab
              fontWeight="600"
              _selected={{
                color: "green.800",
                bg: "green.100",
                borderBottom: "0",
                borderColor: "green.200",
                _hover: { bg: "green.200", borderColor: "green.200" }
              }}
              _hover={{ bg: "gray.200", borderColor: "gray.300" }}
            >
              ACCEPTED
              <Tag
                borderRadius="xl"
                ml="0.5rem"
                bg="green.50"
                border="1px"
                borderColor="green.300"
                color="green.800"
                fontWeight="600"
              >
                {goodFiles?.length}
              </Tag>
            </Tab>

            <Tab
              fontWeight="600"
              _selected={{
                color: "orange.800",
                bg: "orange.100",
                borderBottom: "0",
                borderColor: "orange.200",
                _hover: { bg: "orange.200", borderColor: "orange.200" }
              }}
              _hover={{ bg: "gray.200", borderColor: "gray.300" }}
            >
              REJECTED
              <Tag
                ml="0.5rem"
                borderRadius="xl"
                bg="orange.50"
                border="1px"
                borderColor="orange.300"
                color="orange.800"
                fontWeight="600"
              >
                {badFiles?.length}
              </Tag>
            </Tab>
          </TabList>

          <TabPanels
            alignContent={"center"}
            align="center"
            border="1px"
            borderColor="gray.100"
            borderBottomEndRadius="lg"
            borderBottomStartRadius="lg"
          >
            <TabPanel>
              <FileCardList
                files={goodFiles}
                onChange={setGoodFiles}
                label={goodFilesLabel}
              />
            </TabPanel>
            <TabPanel>
              <FileCardList
                canUpload={false}
                files={badFiles}
                onChange={setBadFiles}
                label={badFilesLabel}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}
    </Flex>
  );
};

export default FileUploader;
