import { Flex } from "@chakra-ui/react";
import TopBar from "../TopBar";

const Layout = ({ children }) => {
  return (
    <Flex direction={"column"} w="100%" h="100vh" overflow="hidden">
      <TopBar />
      <Flex
        direction={"column"}
        w="100%"
        grow="1"
        overflow="auto"
        p={{ base: "4", md: "12" }}
      >
        {children}
      </Flex>
    </Flex>
  );
};

export default Layout;
