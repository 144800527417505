import { DownloadIcon } from "@chakra-ui/icons";
import { Button, Tooltip } from "@chakra-ui/react";
import axios from "axios";
import { storage } from "../../../../helpers";
import { API_URL } from "../../../../config";

const UploadExportButton = ({ row }) => {
  const downloadFile = () => {
    axios
      .post(
        API_URL + "export-download",
        { params: { uploadId: row.uploadId } },
        {
          headers: {
            Authorization: "Bearer " + storage.getToken()
          }
        }
      )
      .then(response => {
        axios({
          url: response.data,
          method: "GET",
          responseType: "blob"
        }).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${row.uploadId}.xlsx`);
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch(error => console.log(error));
  };

  return (
    <Tooltip hasArrow label="Download OCR results">
      <Button onClick={downloadFile} disabled={row.status !== "PROCESSED"}>
        <DownloadIcon />
      </Button>
    </Tooltip>
  );
};

export default UploadExportButton;
