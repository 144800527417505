import { CloseButton } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { chakra, Progress, Text, Flex } from "@chakra-ui/react";
import { useEffect, useState } from "react";

const FileCard = ({ file, onDelete, index, disabled, uploadPercentage }) => {
  const Icon = chakra("i", {
    baseStyle: {
      fontSize: "3rem"
    }
  });

  const [percentage, setPercentage] = useState(uploadPercentage);

  const getLogo = file =>
    file?.type
      ? file.type.startsWith("image/")
        ? { type: "image", color: "orange.300" }
        : file.type === "application/pdf"
        ? { type: "pdf", color: "red.200" }
        : { type: "times", color: "red.700" }
      : { type: "times", color: "red.700" };

  useEffect(() => {
    setPercentage(uploadPercentage);
  }, [uploadPercentage]);

  return (
    <Flex
      mb="1rem"
      boxShadow={"xl"}
      borderRadius={"md"}
      border="1px"
      borderColor="gray.300"
      direction={"column"}
      justifyContent={"space-between"}
      overflow={"hidden"}
      shrink={"0"}
    >
      <Flex
        align="center"
        p={"1rem"}
        direction={"row"}
        justify={"space-between"}
        flexGrow={"1"}
      >
        <Icon
          className={`fad fa-file-${getLogo(file).type}`}
          color={getLogo(file).color}
        />
        <Text isTruncated> {file?.name ? file.name : file.file.name}</Text>
        <CloseButton
          onClick={() => {
            onDelete(index);
          }}
          isDisabled={disabled}
          sx={{ border: "1px", borderColor: "gray.300" }}
        />
      </Flex>
      {percentage > 0 && (
        <Progress
          w={"100%"}
          value={percentage}
          hasStripe
          alignSelf={"flex-end"}
        />
      )}
    </Flex>
  );
};

FileCard.propTypes = {
  onDelete: PropTypes.func
};

FileCard.defaultProps = {
  onDelete: () => {}
};

export default FileCard;
