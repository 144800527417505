import {
  addDays,
  endOfDay,
  endOfMonth,
  endOfYear,
  isValid,
  startOfDay,
  startOfMonth,
  startOfYear,
  subMonths,
  subYears
} from "date-fns";

export const formatTimestamp = (
  timestamp,
  options = {
    weekday: "long",
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric"
  }
) => new Date(timestamp).toLocaleString("en-GB", options);

export const getCompleteDateRange = dateRange => {
  if (!dateRange || !isValid(dateRange?.from) || !isValid(dateRange?.to))
    return undefined;
  return { from: startOfDay(dateRange?.from), to: endOfDay(dateRange?.to) };
};

export const getTodayRange = () => {
  const dateRange = { from: new Date(), to: new Date() };
  return getCompleteDateRange(dateRange);
};

export const getYesterdayRange = () => {
  const yesterday = addDays(new Date(), -1);
  const dateRange = { from: yesterday, to: yesterday };
  return getCompleteDateRange(dateRange);
};

export const getLastSevenDaysRange = () => {
  const lastWeek = addDays(new Date(), -6);
  const dateRange = { from: lastWeek, to: new Date() };
  return getCompleteDateRange(dateRange);
};

export const getLastThirtyDaysRange = () => {
  const thirtyDaysAgo = addDays(new Date(), -29);
  const dateRange = { from: thirtyDaysAgo, to: new Date() };
  return getCompleteDateRange(dateRange);
};

export const getCurrentMonthRange = () => {
  const start = startOfMonth(new Date());
  const end = endOfMonth(new Date());
  const dateRange = { from: start, to: end };
  return getCompleteDateRange(dateRange);
};

export const getLastMonthRange = () => {
  const lastMonth = subMonths(new Date(), 1);
  const start = startOfMonth(lastMonth);
  const end = endOfMonth(lastMonth);
  const dateRange = { from: start, to: end };
  return getCompleteDateRange(dateRange);
};

export const getCurrentYearRange = () => {
  const start = startOfYear(new Date());
  const end = endOfYear(new Date());
  const dateRange = { from: start, to: end };
  return getCompleteDateRange(dateRange);
};

export const getLastYearRange = () => {
  const lastYear = subYears(new Date(), 1);
  const start = startOfYear(lastYear);
  const end = endOfYear(lastYear);
  const dateRange = { from: start, to: end };
  return getCompleteDateRange(dateRange);
};
