import { Routes, Route, Navigate } from "react-router-dom";
import UploadFiles from "../views/UploadFiles";

const UploadFilesRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<UploadFiles />} />
      <Route path="*" element={<Navigate to="." />} />
    </Routes>
  );
};

export default UploadFilesRoutes;
