import React from "react";
import { Flex } from "@chakra-ui/layout";

const Grid = ({ children, ...otherProps }) => {
  return (
    <Flex direction="column" boxSizing="border-box" {...otherProps}>
      {children}
    </Flex>
  );
};

export default Grid;
