import Axios from "axios";
import { storage } from "../helpers";

export const API_URL = process.env.REACT_APP_API_URL;

export const config = {
  REGION: process.env.REACT_APP_REGION,
  USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
  APP_CLIENT_ID: process.env.REACT_APP_APP_CLIENT_ID,
  // IDENTITY_POOL_ID: ""
};

export const axios = Axios.create({
  baseURL: API_URL
});

axios.interceptors.request.use(
  config => {
    const token = storage.getToken();

    if (token) config.headers.Authorization = `Bearer ${token}`;

    config.headers.Accept = "application/json";
    return config;
  },
  error => {
    console.log(error);
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    console.log(error);
    return Promise.reject(error);
  }
);
