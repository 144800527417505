import { ViewIcon } from "@chakra-ui/icons";
import { Button, Tooltip } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const ViewUploadFileButton = ({ row }) => {
  const navigate = useNavigate();

  const fileName = row.fileName.split("/")[2];

  const handleClick = () => {
    navigate(`/uploads/${row.uploadId}/${fileName}`);
  };

  return (
    <Tooltip hasArrow label="View file">
      <Button onClick={handleClick}>
        <ViewIcon />
      </Button>
    </Tooltip>
  );
};

export default ViewUploadFileButton;
