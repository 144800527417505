import { Heading, Spinner } from "@chakra-ui/react";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../../../auth";
import { API_URL } from "../../../../config";
import { storage } from "../../../../helpers";
import { Alert, Grid } from "../../../../components";
import { css } from "@emotion/react";
import PdfViewer from "../PdfViewer";

const FileViewer = () => {
  const [fileBlob, setFileBlob] = useState(null);
  const [error, setError] = useState(false);
  const location = useLocation();
  const user = useAuth();

  const email = user.user.email;
  const uploadId = location.pathname.split("/")[2];
  const fileName = decodeURI(location.pathname.split("/")[3].replace());

  const getDownloadPreSignedUrl = useCallback(async () => {
    return axios.post(
      API_URL + "s3-download",
      {
        params: {
          fileName: `${email}/${uploadId}/${fileName}`,
          uploadId: uploadId
        }
      },
      {
        headers: {
          Authorization: "Bearer " + storage.getToken()
        }
      }
    );
  }, [email, fileName, uploadId]);

  const getFile = useCallback(() => {
    getDownloadPreSignedUrl()
      .then(response => {
        console.log(response);
        const downloadUrl = response.data;

        axios
          .get(downloadUrl, {
            responseType: "blob"
          })
          .then(response => {
            setFileBlob(URL.createObjectURL(response.data));
          })
          .catch(error => {
            console.log(error);
            setError(true);
          });
      })
      .catch(error => {
        console.log(error);
        setError(true);
      });
  }, [getDownloadPreSignedUrl]);

  useEffect(() => {
    getFile();
  }, [getFile]);

  return (
    <Grid
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;
      `}
    >
      <Heading as="h1" size="lg" mb="4">
        {fileName}
      </Heading>

      {!fileBlob && !error ? (
        <Spinner size="xl" />
      ) : error ? null : fileName.split(".").pop().toLowerCase() !== "pdf" ? (
        <img alt="document viewer" src={fileBlob} />
      ) : (
        <PdfViewer fileBlob={fileBlob} />
      )}
      <Alert
        show={error}
        title={"Something went wrong!"}
        description={"Please refresh the page to try again."}
        status="warning"
        mb="6"
        onClose={() => setError(false)}
      />
    </Grid>
  );
};
export default FileViewer;
