import { Popover, PopoverTrigger, PopoverContent } from "@chakra-ui/popover";
import {
  InputGroup,
  InputLeftElement,
  Input,
  InputRightElement
} from "@chakra-ui/input";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { format, getYear, isValid } from "date-fns";
import { Portal } from "@chakra-ui/portal";
import { HStack, VStack } from "@chakra-ui/layout";
import { DayPicker } from "react-day-picker";
import {
  getCompleteDateRange,
  getCurrentMonthRange,
  getCurrentYearRange,
  getLastMonthRange,
  getLastSevenDaysRange,
  getLastThirtyDaysRange,
  getLastYearRange,
  getTodayRange,
  getYesterdayRange
} from "../../helpers";
import RangeButton from "./RangeButton";
import PropTypes from "prop-types";
import { Button, CloseButton } from "@chakra-ui/react";
import "react-day-picker/style.css";
import "./DatePicker.css";

const DateRangePicker = ({
  defaultSelected,
  dateFormat,
  placeholder,
  inputProps,
  waitForValidInput,
  onChange,
  allowReset,
  ...datePickerProps
}) => {
  const [range, setRange] = useState(defaultSelected);
  const [key, setKey] = useState(1);

  const initRef = useRef();

  useEffect(() => {
    setRange(defaultSelected);
    setKey(prevKey => prevKey + 1);
  }, [defaultSelected]);

  const handleSelect = val => {
    if (val && isValid(val.from) && isValid(val.to)) {
      onChange(getCompleteDateRange(val));
    } else if (!waitForValidInput) {
      onChange(val);
    }

    setRange(val);
  };

  return (
    <Popover isLazy initialFocusRef={initRef}>
      {({ onClose }) => (
        <Fragment>
          <PopoverTrigger>
            <InputGroup>
              <InputLeftElement
                pointerEvents={"none"}
                color={"gray.400"}
                fontSize={"1rem"}
              >
                <i className={`far fa-calendar-week`} />
              </InputLeftElement>
              <Input
                value={
                  range && isValid(range.from) && isValid(range.to)
                    ? `${format(range.from, dateFormat)} - ${format(
                        range.to,
                        dateFormat
                      )}`
                    : ""
                }
                placeholder={placeholder}
                isReadOnly
                {...inputProps}
              />
              {allowReset && (
                <InputRightElement>
                  <CloseButton
                    onClick={() => {
                      handleSelect({ from: null, to: null });
                      setKey(prevKey => prevKey + 1);
                    }}
                    size={"sm"}
                  />
                </InputRightElement>
              )}
            </InputGroup>
          </PopoverTrigger>
          <Portal>
            <PopoverContent w="fit-content">
              <HStack spacing={"0"}>
                <DayPicker
                  key={key.toString()}
                  fromYear={2021}
                  toYear={getYear(new Date()) + 1}
                  captionLayout="dropdown"
                  mode="range"
                  defaultSelected={range?.to ? range : undefined}
                  defaultMonth={range?.to ?? new Date()}
                  selected={range?.to ? range : undefined}
                  onSelect={handleSelect}
                  {...datePickerProps}
                />
                <VStack spacing={"2"} p={"2"}>
                  <RangeButton
                    ref={initRef}
                    onClick={() => {
                      handleSelect(getTodayRange());
                      onClose();
                    }}
                    label={"Today"}
                  />
                  <RangeButton
                    onClick={() => {
                      handleSelect(getYesterdayRange());
                      onClose();
                    }}
                    label={"Yesterday"}
                  />
                  <RangeButton
                    onClick={() => {
                      handleSelect(getLastSevenDaysRange());
                      onClose();
                    }}
                    label={"Last 7 days"}
                  />
                  <RangeButton
                    onClick={() => {
                      handleSelect(getLastThirtyDaysRange());
                      onClose();
                    }}
                    label={"Last 30 days"}
                  />
                  <RangeButton
                    onClick={() => {
                      handleSelect(getCurrentMonthRange());
                      onClose();
                    }}
                    label={"Current month"}
                  />
                  <RangeButton
                    onClick={() => {
                      handleSelect(getLastMonthRange());
                      onClose();
                    }}
                    label={"Last month"}
                  />
                  <RangeButton
                    onClick={() => {
                      handleSelect(getCurrentYearRange());
                      onClose();
                    }}
                    label={"Current year"}
                  />
                  <RangeButton
                    onClick={() => {
                      handleSelect(getLastYearRange());
                      onClose();
                    }}
                    label={"Last year"}
                  />
                  {allowReset && (
                    <Button
                      w="100%"
                      size="xs"
                      variant="outline"
                      colorScheme={"blue"}
                      fontSize={"sm"}
                      onClick={() => {
                        handleSelect({ from: null, to: null });
                        setKey(prevKey => prevKey + 1);
                      }}
                    >
                      Reset
                    </Button>
                  )}
                </VStack>
              </HStack>
            </PopoverContent>
          </Portal>
        </Fragment>
      )}
    </Popover>
  );
};

DateRangePicker.propTypes = {
  dateFormat: PropTypes.string,
  placeholder: PropTypes.string,
  allowReset: PropTypes.bool,
  waitForValidInput: PropTypes.bool,
  onChange: PropTypes.func
};

DateRangePicker.defaultProps = {
  dateFormat: "dd.MM.yyyy",
  placeholder: "Alege o perioada",
  allowReset: true,
  waitForValidInput: false,
  onChange: () => {}
};

export default DateRangePicker;
