import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { useCallback, useMemo, useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

const PdfViewer = ({ fileBlob }) => {
  const [numberOfPages, setNumberOfPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(null);
  const [scale, setScale] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumberOfPages(numPages);
    setPageNumber(1);
  };

  const changePage = offset => {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  };

  const changeScale = offset => {
    setScale(prevScale => prevScale + offset);
  };

  const previousPage = useCallback(() => {
    changePage(-1);
  }, []);

  const nextPage = useCallback(() => {
    changePage(1);
  }, []);

  const zoomOut = useCallback(() => {
    changeScale(-0.25);
  }, []);

  const zoomIn = useCallback(() => {
    changeScale(0.25);
  }, []);

  const pdfActions = useMemo(() => {
    return (
      <Flex
        align="center"
        justify="space-between"
        wrap="wrap"
        css={{ gap: "1rem" }}
      >
        <Flex align="center" justify="space-between" css={{ gap: "1rem" }}>
          <Button
            leftIcon={<ChevronLeftIcon />}
            disabled={pageNumber <= 1}
            onClick={previousPage}
          >
            Previous
          </Button>

          <Text fontSize="md" fontWeight={500}>
            Page {pageNumber || (numberOfPages ? 1 : "--")} of{" "}
            {numberOfPages || "--"}
          </Text>

          <Button
            rightIcon={<ChevronRightIcon />}
            disabled={pageNumber >= numberOfPages}
            onClick={nextPage}
          >
            Next
          </Button>
        </Flex>

        <Flex align="center" justify="space-between" css={{ gap: "1rem" }}>
          <Button
            leftIcon={<i className="fas fa-search-minus" />}
            disabled={scale <= 0.25}
            onClick={zoomOut}
          >
            Zoom out
          </Button>

          <Button
            leftIcon={<i className="fas fa-search-plus" />}
            disabled={scale >= 3}
            onClick={zoomIn}
          >
            Zoom in
          </Button>
        </Flex>
      </Flex>
    );
  }, [
    nextPage,
    numberOfPages,
    pageNumber,
    previousPage,
    scale,
    zoomIn,
    zoomOut
  ]);

  return (
    <Flex direction="column" css={{ gap: "1rem" }} w="100%" maxW="50rem">
      {pdfActions}
      <Box
        overflow="auto"
        w="100%"
        maxW="100%"
        border="1px"
        borderRadius="md"
        borderColor="gray.300"
      >
        <Document file={fileBlob} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} scale={scale} />
        </Document>
      </Box>
    </Flex>
  );
};

export default PdfViewer;
