import { CheckIcon } from "@chakra-ui/icons";
import { Spinner, Tag, TagLabel, TagLeftIcon } from "@chakra-ui/react";

const StatusPill = ({ status }) => {
  return (
    <Tag
      size={"lg"}
      key={"lg"}
      variant="subtle"
      colorScheme={status === "PROCESSED" ? "green" : "orange"}
    >
      <TagLeftIcon
        boxSize="12px"
        as={status === "PROCESSED" ? CheckIcon : Spinner}
      />
      <TagLabel>{status}</TagLabel>
    </Tag>
  );
};

export default StatusPill;
