import { storage } from "../helpers/storage";
import { getUser, login, logout, register } from "./api";
import LoadingScreen from "./components/LoadingScreen";
import { initReactQueryAuth } from "react-query-auth";

const handleError = error => {
  console.log(error);
  storage.clearToken();
  window.location.assign(window.location.origin);
};

const handleUserResponse = data => {
  const { email: id } = data;
  const token = data.signInUserSession.idToken.jwtToken;

  if (token) storage.setToken(token);

  const user = { id, email: data.attributes.email };
  return user;
};

async function loadUser() {
  if (storage.getToken()) {
    const response = await getUser();
    const user = handleUserResponse(response);
    return user;
  }
  return null;
}

async function loginFn(data) {
  const response = await login(data);
  const user = handleUserResponse(response);
  return user;
}

async function registerFn(data) {
  await register(data);
}

async function logoutFn(data) {
  await logout(data);
  storage.clearToken();
}

const authConfig = {
  loadUser,
  loginFn,
  registerFn,
  logoutFn,
  LoaderComponent: () => {
    return <LoadingScreen />;
  },
  ErrorComponent: error => handleError(error)
};

export const { AuthProvider, useAuth } = initReactQueryAuth(authConfig);
