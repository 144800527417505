import { Box } from "@chakra-ui/layout";
import { cloneElement, useEffect, useState } from "react";
import { Grid, Row } from "../FlexboxGrid";
import { HeaderItem } from "./";
import { TableRow } from "./";
import PropTypes from "prop-types";
import { withErrorBoundary } from "react-error-boundary";
import TableError from "./TableError";
import { useQuery } from "react-query";
import { Text } from "@chakra-ui/react";

const Table = ({
  columnGroups,
  rowMinWidth,
  identifier,
  columns,
  actions,
  highlightRows,
  idKey,
  hoverable,
  afterTable,
  initialQueryVars,
  query,
  ...otherProps
}) => {
  const [queryVars, setQueryVars] = useState({
    ...initialQueryVars,
    filter: {
      ...initialQueryVars.filter,
      ...columns
        .filter(column => column.filter)
        .reduce((acc, col) => {
          return { ...acc, [col.filter.field]: col.filter.defaultValue };
        }, {})
    }
  });

  useEffect(() => {
    setQueryVars(oldQueryVars => {
      return {
        ...oldQueryVars,
        sort: { ...oldQueryVars.sort, ...initialQueryVars.sort },
        filter: { ...oldQueryVars.filter, ...initialQueryVars.filter },
        range: { ...oldQueryVars.range, ...initialQueryVars.range }
      };
    });
  }, [initialQueryVars]);

  const onFilter = newFilters =>
    setQueryVars(oldQueryVars => {
      return {
        ...oldQueryVars,
        filter: { ...oldQueryVars.filter, ...newFilters }
      };
    });

  const onSort = newSorts => {
    setQueryVars(oldQueryVars => {
      return {
        ...oldQueryVars,
        sort: { ...oldQueryVars.sort, ...newSorts }
      };
    });
  };

  const getHighlight = (highlight, data, index) =>
    typeof highlight === "function"
      ? highlight(data)
      : typeof highlight === "string"
      ? index % 2 === (highlight === "even" ? 0 : 1)
      : false;

  const {
    data: tableData,
    isLoading,
    refetch
  } = useQuery(
    [identifier, queryVars],
    ({ queryKey: [_id, reqParams] }) => {
      return query(reqParams);
    },
    { refetchInterval: 5000 }
  );

  return (
    <Grid
      as="table"
      position="relative"
      borderRadius="md"
      borderWidth="1px"
      borderColor="gray.300"
      overflow="auto"
      width={"100%"}
      sx={{
        "& > *:first-child": {
          borderTopLeftRadius: "0.375rem",
          borderTopRightRadius: "0.375rem"
        },
        "& > *:last-child": {
          borderBottomLeftRadius: "0.375rem",
          borderBottomRightRadius: "0.375rem"
        }
      }}
      {...otherProps}
    >
      {columnGroups && (
        <Row
          as="thead"
          spacing="0"
          sx={{
            "th:last-child": { borderRight: 0 }
          }}
          wrap="nowrap"
          minWidth={`${rowMinWidth}rem`}
        >
          {columnGroups.map(column => {
            return (
              <HeaderItem
                column={column}
                key={`table-${identifier}-column-header-group-${column.title}`}
              />
            );
          })}
        </Row>
      )}

      <Row
        as="thead"
        spacing={"0"}
        position="sticky"
        top="0"
        zIndex="1"
        sx={{
          "th:last-child": { borderRight: 0 }
        }}
        wrap="nowrap"
        minWidth={`${rowMinWidth}rem`}
      >
        {columns.map(column => (
          <HeaderItem
            column={column}
            filters={queryVars.filter}
            onFilter={onFilter}
            onSort={onSort}
            key={`table-${identifier}-column-header-${column.title}-${column.fields[0]}`}
          ></HeaderItem>
        ))}

        {typeof actions !== "undefined" && (
          <HeaderItem
            column={actions}
            key={`table-${identifier}-column-header-actions`}
          ></HeaderItem>
        )}
      </Row>

      <Box
        as="tbody"
        sx={{
          "tr:last-child": { borderBottom: 0 }
        }}
      >
        {isLoading ? (
          Array(10)
            .fill()
            .map((_, index) => (
              <TableRow
                loading
                columns={columns}
                key={`table-${identifier}-${index}-row-loading`}
                actions={actions}
                rowMinWidth={rowMinWidth}
              />
            ))
        ) : (
          <>
            {!tableData.data.length && (
              <Box h="5rem">
                <Box
                  textAlign="center"
                  position="absolute"
                  left="50%"
                  mt="2.5rem"
                  transform="translate(-50%, -50%)"
                >
                  <Text>No records were found for the selected criteria.</Text>
                </Box>
              </Box>
            )}
            {tableData.data.map((rowData, index) => {
              return (
                <TableRow
                  index={index}
                  columns={columns}
                  rowData={rowData}
                  key={`table-${identifier}-column-row-${index}-${rowData[idKey]}`}
                  identifier={`table-${identifier}-column-row-${index}-${rowData[idKey]}`}
                  actions={actions}
                  highlighted={getHighlight(highlightRows)}
                  hoverable={hoverable}
                  rowMinWidth={rowMinWidth}
                  currentQueryVars={queryVars}
                  refetch={refetch}
                />
              );
            })}
          </>
        )}
      </Box>

      {afterTable &&
        (typeof afterTable === "function"
          ? afterTable({ tableData })
          : cloneElement(afterTable, { tableData }))}
    </Grid>
  );
};

Table.propTypes = {
  identifier: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      fields: PropTypes.arrayOf(PropTypes.string).isRequired,
      title: PropTypes.string,
      size: PropTypes.number.isRequired,
      renderer: PropTypes.func,
      sortable: PropTypes.bool,
      tooltip: PropTypes.bool,
      align: PropTypes.oneOf(["left", "center", "right"]),
      filter: PropTypes.shape({
        field: PropTypes.string,
        name: PropTypes.string,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            value: PropTypes.oneOfType([
              PropTypes.string,
              PropTypes.arrayOf(PropTypes.string)
            ]),
            display: PropTypes.string
          })
        ),
        defaultValue: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.arrayOf(PropTypes.string)
        ])
      })
    })
  ).isRequired,
  actions: PropTypes.oneOfType([
    PropTypes.shape({
      size: PropTypes.number,
      title: PropTypes.string,
      align: PropTypes.oneOf(["left", "center", "right"]),
      actions: PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.shape({
            icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
              .isRequired,
            tooltip: PropTypes.string,
            onClick: PropTypes.func.isRequired,
            disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func])
          }),
          PropTypes.element
        ])
      )
    }),
    PropTypes.element
  ]),
  highlightRows: PropTypes.oneOfType([
    PropTypes.oneOf(["odd", "even"]),
    PropTypes.func
  ]),
  hoverable: PropTypes.bool,
  idKey: PropTypes.string
};

Table.defaultProps = {
  identifier: Math.random().toString(36).substring(7),
  actions: undefined,
  highlightRows: undefined,
  rowMinWidth: 0,
  hoverable: false,
  columnGroups: undefined,
  idKey: "id"
};

export default withErrorBoundary(Table, { fallbackRender: TableError });
