import { useEffect, useState } from "react";
import { Col } from "../FlexboxGrid";
import PropTypes from "prop-types";
import { Flex, Text } from "@chakra-ui/layout";
import { IconButton } from "@chakra-ui/button";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuGroup,
  MenuItemOption,
  MenuDivider
} from "@chakra-ui/menu";
import { Portal } from "@chakra-ui/react";

const HeaderItem = ({
  sorts,
  column,
  sortCombine,
  onSort,
  onFilter,
  filters
}) => {
  const columnSort = sorts.find(s => s.field === column.fields[0]);
  const initialSort = columnSort ? columnSort.direction || "ASC" : false;
  const [sort, setSort] = useState(initialSort);
  const [isFilterActive, setIsFilterActive] = useState(false);

  const translateAlighToFlex = align =>
    align === "left" ? "flex-start" : align === "right" ? "flex-end" : "center";

  const doSort = () => {
    const direction = sort === "ASC" ? "DESC" : "ASC";

    const newSorts = [
      ...(sortCombine
        ? sorts.filter(s => !column.fields.includes(s.field))
        : []),
      ...column.fields.map(field => ({ [field]: direction }))
    ];

    onSort(Object.assign(...newSorts));
    setSort(direction);
  };

  useEffect(() => {
    const columnSort = sorts.find(s => s.field === column.fields[0]);
    !columnSort ? setSort(false) : setSort(columnSort.direction);
  }, [column.fields, sorts]);

  return (
    <Col
      as="th"
      bg="gray.100"
      boxSizing="border-box"
      xs={column.size}
      borderBottom="1px"
      borderRight={column.sticky === "right" ? 0 : "1px"}
      borderLeft={column.sticky === "right" ? "1px" : 0}
      borderColor="gray.300"
      spacing="0"
      {...(column.sticky && { position: "sticky", [column.sticky]: 0 })}
    >
      <Flex
        w="100%"
        boxSizing="border-box"
        color="gray.700"
        lineHeight="4"
        px="4"
        py="2"
        fontSize={"sm"}
        align={"center"}
        justify={translateAlighToFlex(column.align || "left")}
      >
        <Text
          textTransform={"uppercase"}
          letterSpacing={"wider"}
          textAlign={column.align || "left"}
          cursor={column.sortable ? "pointer" : "default"}
          onClick={() => {
            if (column.sortable) doSort();
          }}
        >
          {column.title}
        </Text>

        {column.sortable && (
          <IconButton
            ml="2"
            size="xs"
            fontSize={"md"}
            onClick={() => {
              if (column.sortable) doSort();
            }}
          >
            <i
              className={`fas fa-sort${
                sort ? (sort === "ASC" ? "-up" : "-down") : ""
              }`}
            />
          </IconButton>
        )}

        {column.filter && (
          <Menu isLazy>
            <MenuButton as={IconButton} ml={"2"} size="xs" fontSize={"md"}>
              <i className={`${isFilterActive ? "fas" : "far"} fa-filter`} />
            </MenuButton>

            <Portal zIndex={2}>
              <MenuList>
                <MenuGroup
                  fontSize={"md"}
                  title={`Filter by ${column.filter.name}`}
                  type="radio"
                >
                  {column.filter.options.map((f, index) => (
                    <MenuItemOption
                      isChecked={filters[column.filter.field] === f.value}
                      key={`${index}-${f.display}`}
                      onClick={() => {
                        onFilter({ [column.filter.field]: f.value });
                        setIsFilterActive(true);
                      }}
                    >
                      {f.display}
                    </MenuItemOption>
                  ))}

                  <MenuDivider />

                  <MenuItemOption
                    isChecked={
                      filters[column.filter.field]
                        ? filters[column.filter.field] ===
                          (column.filter?.defaultValue || null)
                        : true
                    }
                    onClick={() => {
                      onFilter({
                        [column.filter.field]:
                          column.filter.defaultValue || null
                      });
                      setIsFilterActive(false);
                    }}
                  >
                    Reset filter
                  </MenuItemOption>
                </MenuGroup>
              </MenuList>
            </Portal>
          </Menu>
        )}
      </Flex>
    </Col>
  );
};

HeaderItem.propTypes = {
  sorts: PropTypes.array,
  onSort: PropTypes.func
};

HeaderItem.defaultProps = {
  sorts: [],
  onSort: () => {}
};

export default HeaderItem;
