export const extendedTheme = {
  fonts: {
    heading: "Geomanist",
    body: "Geomanist"
  },
  colors: {
    blue: {
      50: "#E5EFFF",
      100: "#B8D3FF",
      200: "#8AB7FF",
      300: "#5C9BFF",
      400: "#2E7FFF",
      500: "#0063FF",
      600: "#004FCC",
      700: "#003B99",
      800: "#002766",
      900: "#001433"
    },
    green: {
      50: "#EAFAF4",
      100: "#C4F2E0",
      200: "#9FEACC",
      300: "#79E2B8",
      400: "#53DAA4",
      500: "#2DD290",
      600: "#24A873",
      700: "#1B7E56",
      800: "#125439",
      900: "#092A1D"
    },
    red: {
      50: "#FFE6E6",
      100: "#FEB9B9",
      200: "#FD8C8C",
      300: "#FC5F5F",
      400: "#FB3232",
      500: "#FA0505",
      600: "#C80404",
      700: "#960303",
      800: "#640202",
      900: "#320101"
    },
    yellow: {
      50: "#FFF7E5",
      100: "#FFE9B8",
      200: "#FFDB8A",
      300: "#FFCD5C",
      400: "#FFBF2E",
      500: "#FFB100",
      600: "#CC8D00",
      700: "#996A00",
      800: "#664700",
      900: "#332300"
    },
    orange: {
      50: "#FFF0E5",
      100: "#FFD6B8",
      200: "#FFBC8A",
      300: "#FFA15C",
      400: "#FF872E",
      500: "#FF6C00",
      600: "#CC5700",
      700: "#994100",
      800: "#662B00",
      900: "#331600"
    },
    pink: {
      50: "#FFE5F4",
      100: "#FFB8E1",
      200: "#FF8ACE",
      300: "#FF5CBB",
      400: "#FF2EA8",
      500: "#FF0095",
      600: "#CC0077",
      700: "#990059",
      800: "#66003C",
      900: "#33001E"
    },
    purple: {
      50: "#F3EBFA",
      100: "#DEC6F1",
      200: "#C9A1E8",
      300: "#B47CDF",
      400: "#9E57D6",
      500: "#8932CD",
      600: "#6E28A4",
      700: "#521E7B",
      800: "#371452",
      900: "#1B0A29"
    }
  }
};
