import FileCard from "./FileCard";
import { Tag, Button, Spacer, Flex, useToast } from "@chakra-ui/react";
import axios from "axios";
import { API_URL } from "../../config";
import { useCallback, useMemo, useState } from "react";
import { storage } from "../../helpers";
import { v4 as uuidv4 } from "uuid";
import { useAuth } from "../../auth";

const FileCardList = ({ files, onChange, label, canUpload = true }) => {
  const toast = useToast();
  const user = useAuth();

  const [loading, setLoading] = useState(false);
  const [percentage, setPercentage] = useState(undefined);
  const [currentIndex, setCurrentIndex] = useState(0);

  const deleteFile = index => {
    const filteredFiles = files.filter((_, idx) => idx !== index);
    onChange(filteredFiles);
  };

  const config = useMemo(
    () => ({
      onUploadProgress: progressEvent => {
        setPercentage(
          Math.round((progressEvent.loaded * 100) / progressEvent.total)
        );
      }
    }),
    []
  );

  const handleUpload = useCallback(
    files => {
      setLoading(true);

      let index = 0;
      const uploadId = uuidv4();
      const timestamp = Math.floor(Date.now() / 1000);
      const email = user.user.email;

      const request = () => {
        return axios
          .post(
            API_URL + "s3-upload",
            {
              params: {
                fileName: `${email}/${uploadId}/${files[index].name}`,
                uploadId: uploadId,
                timestamp: timestamp.toString()
              }
            },
            {
              headers: {
                Authorization: "Bearer " + storage.getToken()
              }
            }
          )
          .then(response => {
            const formData = new FormData();
            Object.entries(response.data.fields).forEach(([k, v]) => {
              formData.append(k, v);
            });
            formData.append("file", files[index]);

            axios
              .post(response.data.url, formData, config)
              .then(response => {
                index++;

                setCurrentIndex(oldIndex => oldIndex + 1);

                if (index === files.length) {
                  setLoading(false);
                  setPercentage(0);
                  setCurrentIndex(0);
                  onChange([]);
                  toast({
                    title: "Files uploaded successfully!",
                    description: `${files.length} files uploaded...`,
                    status: "success",
                    duration: 9000,
                    isClosable: true
                  });
                }

                if (index >= files.length) return;
                return request();
              })
              .catch(error => console.log(error));
          })
          .catch(error => console.log(error));
      };
      return request();
    },
    [config, onChange, toast, user.user.email]
  );

  const filesActions = useMemo(() => {
    return (
      <Flex align={"center"} mb="1rem">
        <Tag textAlign={"center"} fontSize="md" mr="1rem">
          {label}
        </Tag>
        <Spacer />
        <Button
          leftIcon={<i className="fas fa-trash-alt" />}
          disabled={loading || !files.length}
          onClick={() => onChange([])}
          colorScheme={"orange"}
          variant={"outline"}
        >
          Delete all
        </Button>
        {canUpload && (
          <Button
            disabled={!files.length}
            ml={"4"}
            isLoading={loading}
            leftIcon={<i className="fas fa-cloud-upload-alt" />}
            onClick={() => handleUpload(files)}
            colorScheme={"blue"}
          >
            Upload
          </Button>
        )}
      </Flex>
    );
  }, [canUpload, files, handleUpload, label, loading, onChange]);

  return (
    <Flex width="100%" mt={"1rem"} direction={"column"}>
      {filesActions}
      {files.map((file, index) => (
        <FileCard
          file={file}
          key={`${index}${file.name}`}
          onDelete={deleteFile}
          index={index}
          disabled={loading}
          uploadPercentage={
            currentIndex === index ? percentage : currentIndex > index ? 100 : 0
          }
        />
      ))}
    </Flex>
  );
};

export default FileCardList;
