import { DeleteIcon } from "@chakra-ui/icons";
import { Button, Tooltip } from "@chakra-ui/react";
import { useLazyAxios } from "use-axios-client";
import { API_URL } from "../../../../config";
import { storage } from "../../../../helpers";
import { useToast } from "@chakra-ui/react";

const DeleteExportButton = ({ row, refetch }) => {
  const toast = useToast();
  const [getData] = useLazyAxios({
    baseURL: API_URL,
    url: "delete-upload",
    method: "post",
    params: {
      timestamp: row.timestamp
    },
    headers: {
      Authorization: "Bearer " + storage.getToken()
    }
  });

  const handleClick = () => {
    getData()
      .then(() =>
        refetch()
          .then(() =>
            toast({
              title: "Delete upload",
              description: "Your upload was deleted successfully",
              status: "success",
              duration: 5000,
              isClosable: true
            })
          )
          .catch(error => console.log(error))
      )
      .catch(error => {
        console.log(error);
        toast({
          title: "Delete upload",
          description: "Your upload could not be deleted",
          status: "error",
          duration: 5000,
          isClosable: true
        });
      });
  };

  return (
    <Tooltip hasArrow label="Delete upload">
      <Button onClick={handleClick} disabled={row.status !== "PROCESSED"}>
        <DeleteIcon />
      </Button>
    </Tooltip>
  );
};

export default DeleteExportButton;
