import { useParams } from "react-router-dom";
import { Text, Flex } from "@chakra-ui/react";
import { Table } from "../../../../components/Table";
import { getUploadFiles } from "../../api/getUploadFiles";
import { Tag, TagLabel, TagLeftIcon } from "@chakra-ui/react";
import { TimeIcon } from "@chakra-ui/icons";
import { StatusPill } from "../../../../components";
import { formatTimestamp } from "../../../../helpers";
import ViewUploadFileButton from "../ViewUploadFileButton/ViewUploadFileButton";

const UploadFilesTable = () => {
  const params = useParams();

  const columns = [
    {
      fields: ["fileName"],
      title: "File name",
      size: 4.5,
      renderer: ({ rowData }) => (
        <Text fontWeight={"bold"}>{rowData.fileName.split("/")[2]}</Text>
      )
    },
    {
      fields: ["completedOnTimestamp"],
      title: "Completed at",
      size: 3.5,
      renderer: ({ rowData }) => (
        <Tag size={"md"} key={"md"} variant="subtle" colorScheme="gray">
          <TagLeftIcon boxSize="12px" as={TimeIcon} />
          <TagLabel>
            {rowData?.completedOnTimestamp
              ? formatTimestamp(parseInt(rowData.completedOnTimestamp) * 1000)
              : "N/A"}
          </TagLabel>
        </Tag>
      )
    },
    {
      fields: ["status"],
      title: "Status",
      size: 2.5,
      renderer: ({ rowData }) => <StatusPill status={rowData.status} />,
      filter: {
        field: "status",
        name: "status",
        defaultValue: [],
        options: [
          { value: ["PROCESSED"], display: "Processed" },
          { value: ["PROCESSING"], display: "Processing" }
        ]
      }
    }
  ];

  const actions = {
    size: 1.5,
    title: "ACTIONS",
    align: "right",
    actions: [<ViewUploadFileButton />]
  };

  return (
    <Flex
      direction={"column"}
      width={"100%"}
      overflowX={"hidden"}
      maxW={"100%"}
      justify={"center"}
      align={"center"}
      justifyContent={"center"}
    >
      <Table
        rowMinWidth={"50"}
        identifier={"uploads"}
        highlightRows={"odd"}
        hoverable={true}
        columns={columns}
        actions={actions}
        initialQueryVars={{
          uploadId: params.uploadId
        }}
        idKey={"uploadId"}
        query={getUploadFiles}
      />
    </Flex>
  );
};

export default UploadFilesTable;
