import { Formik, Form as FormikForm } from "formik";
import { cloneElement } from "react";

const Form = ({ children, style, ...formProps }) => {
  return (
    <Formik {...formProps}>
      {props => (
        <FormikForm style={style}>
          {typeof children === "function"
            ? children(props)
            : cloneElement(children, { formikProps: props })}
        </FormikForm>
      )}
    </Formik>
  );
};

export default Form;
