import { Button } from "@chakra-ui/button";
import React from "react";
import { forwardRef } from "@chakra-ui/system";

const RangeButton = forwardRef((props, ref) => {
  const { label, ...rest } = props;

  return (
    <Button
      ref={ref}
      w={"100%"}
      size={"xs"}
      colorScheme={"blue"}
      fontSize={"sm"}
      {...rest}
    >
      {label}
    </Button>
  );
});

export default RangeButton;
