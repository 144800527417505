import { useNavigate } from "react-router-dom";
import { Layout, LoginForm } from "../../components";

const Login = () => {
  const navigate = useNavigate();

  return (
    <Layout>
      <LoginForm onSuccess={() => navigate("/")} />
    </Layout>
  );
};

export default Login;
