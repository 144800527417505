import { useRoutes } from "react-router-dom";
import { useAuth } from "../auth";
import { privateRoutes } from "./privateRoutes";
import { publicRoutes } from "./publicRoutes";

const AppRoutes = () => {
  const { user } = useAuth();
  const routes = user ? privateRoutes() : publicRoutes;

  const app = useRoutes(routes);
  return app;
};

export default AppRoutes;
