import { useNavigate } from "react-router-dom";
import { Layout, RegisterForm } from "../../components";

const Register = () => {
  const navigate = useNavigate();

  return (
    <Layout>
      <RegisterForm onSuccess={() => navigate("/login")} />
    </Layout>
  );
};

export default Register;
